<template>
  <Header v-if="$route.name !== 'SignIn'"></Header>
  <router-view/>
</template>

<script>
import Header from "./components/Header";
export default {
  name: 'App',
  components: {
    Header
  },
  data() {
    return {
      store: window.Store
    }
  },
  created(){
    document.title = "Hexagon | Dealers Portal"

    this.store.api = {
      //endpoint: 'http://localhost/api',
      endpoint: 'https://sfdp.geosystems.ru/api',
      endpoint2: 'https://dealers.geosystems.ru'
    }
  }
}
</script>

<style src="./assets/css/main.css"></style>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
