<template>
  <header>
    <div class="container-fluid">
      <div class="row">
        <div class="col-6">
          <a href="/"><img src="@/assets/img/logo.png" class="header_logo"></a>
        </div>
        <div class="col-6 align-self-center">
          <p class="header_name">{{test}}{{userName}} / <a href="/logout.php" class="text-danger">Выход</a></p>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: {
    userName: String
  }
}
</script>

<style scoped>
  header {
    background: #181f38;
    color: #fff;
    padding: 20px 0px;
  }
</style>