import { createApp, reactive } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'

const Vue = createApp(App)
window.Store = reactive({})

const routes = [
    {
        path: '/signin',
        name: 'SignIn',
        component: () => import('./views/SignIn')
    },
]

const router = createRouter({
    base: '/signin/',
    history: createWebHistory(process.env.BASE_URL),
    routes
})

Vue.use(router).mount('#app')
